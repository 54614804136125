export interface Pagination {
  total: number;
  start: number;
  limit: number;
}

export interface Image {
  thumbnail?: {
    src?: string;
  };
}

export interface Playlist {
  img: string;
  name: string;
  status: string;
  id: string;
  created: string;
  updated: string;
  update: string;
  createdDate: string;
  updatedDate: string;
  type: string;
}

export interface AllVideos extends Playlist {
  created: string;
  update: string;
}

export interface Video extends Playlist {
  description: string;
  expiredTime: string;
  id: string;
  name: string;
  publishedTime: string;
  status: string;
  tags: string;
  thumbnailUrl: string;
  updatedDate: string;
  createdDate: string;
  //deprecated. Please make sure they are part of backend entities
  account_id: string;
  images: Image;
}

export interface VideoBlock extends Video {
  state: boolean;
}

export interface DBVideo extends Playlist {
  createdDate: string;
  updatedDate: string;
}

export interface Right {
  videos: Video[];
  allowToOverride: boolean;
  availability: {
    allowToOverrideAvailability: boolean;
    publishDate: null;
    publishDateOffsetHours: number;
    takedownDate: null;
    takedownOffsetDays: number;
  };
  blacklistedDomains: null;
  brightcoveRestrictionId: string;
  countries: null;
  filtering: number;
  id: string;
  rightsHolder: { id: string; title: string };
  title: string;
  whitelistedDomains: Array<string>;
}

export interface Channel {
  videos(videos: Video[]);
  id?: string;
  name: string;
  description: string;
  language: { id: string; name: string };
  rights?: Right[];
  externalId: string;
  images: { thumbnail: { src: string } };
  publishers: Array<Publisher>;
}

export interface PlaylistVideo {
  images: { thumbnail: { src: string } };
  name: string;
  status: string;
  id: string;
  createdDate: string;
  updatedDate: string;
}

export interface Country {
  id: string;
}

export interface Publisher {
  id: string;
  name: "";
  email: "";
  longitudeId: "";
  country: Country;
  rights: Right[];
  moduleVideoUpload: boolean;
  moduleSemantic: boolean;
  moduleApi: boolean;
  isBlocked: boolean;
}

export enum EmbedCodeTypes {
  MANUAL = "MANUAL",
  SEMANTIC = "SEMANTIC",
  SINGLE = "SINGLE",
  CHANNEL = "CHANNEL",
  SMART = "SMART",
}

export enum PlaylistTypes {
  MANUAL = "MANUAL",
  SMART = "SMART",
}

type styleOpt = "sixteenByNine" | "standard" | "fullWidth" | "popup";

export const styleCodeDic: Record<styleOpt, string> = {
  sixteenByNine: "16:9",
  standard: "Standard",
  fullWidth: "Full width",
  popup: "Popup",
};

interface PlayerStyleData {
  additional: string;
  branding: string;
  isAudio: string;
  style: string;
  styling: styleOpt;
  video: string;
  volumeValue: string;
}
export interface Player {
  name: string;
  id: string;
  type: EmbedCodeTypes;
  styleData: PlayerStyleData;
}

export interface Channel {
  name: string;
  id?: string;
}

export interface EmbedCode {
  default: boolean;
  id: string;
  name: string;
  type: EmbedCodeTypes;
  player?: Player;
  createdDate: string;
  fallbackChannel?: Channel;
  embedCodeUrl?: string;
  ampEmbedCodeUrl?: string;
}

export interface EmbedCodeForm
  extends Omit<EmbedCode, "player" | "fallbackChannel"> {
  fallbackChannel: string;
  player: string;
}

export interface EmbedCodesP extends Pagination {
  codes: EmbedCode[];
}

export interface SemanticArticle {
  embedCode: Array<EmbedCode>;
  id: string;
  name: string;
  url: string;
  locked: boolean;
}

export interface SemanticArticlesP extends Pagination {
  semanticArticles: SemanticArticle[];
}

export interface ChannelsP extends Pagination {
  channels: Channel[];
}

type customCode = "unknown" | "entityRelated";

export const errorDict: Record<customCode, string> = {
  unknown: "Somthing went wrong. Please try again",
  entityRelated:
    "Cannot delete, this player has embed codes attached. Please remove related embed codes to allow deletion of this player.",
};

export interface ErrorApiResponse {
  customCode: customCode;
  message: string;
}
